import {CSSProperties, ReactNode} from 'react';

interface CenterRowProperties {
  children: ReactNode,
  style?: CSSProperties
}

const CenterRow = ({ children, style }: CenterRowProperties) => (
  <div className="basic-flex-row" style={style}>
    { children }
  </div>
);


export default CenterRow;
