import {CSSProperties, HTMLProps, ReactNode, useCallback, useMemo} from 'react';

export type ClickableHandler = (e: React.KeyboardEvent<HTMLDivElement>|React.MouseEvent<HTMLDivElement>) => void;

const Clickable = ({ children, style, onClick, className, disabled, link, ariaLabel, role, divProps }: ClickableProps) => {
  // eslint-disable-next-line
  const defaultRole = useMemo(() => {
    if (disabled) {
      return '';
    }
    if (link) {
      return 'link';
    }
    return 'button';
  }, [disabled, link]);

  const finalOnClick: ClickableHandler = useCallback(e => {
    e.preventDefault()
    onClick(e);
  }, [onClick])

  return (
    <div
      tabIndex={disabled ? undefined : 0}
      className={`clickable ${className}`}
      onClick={!disabled ? finalOnClick : undefined}
      style={style}
      aria-label={ariaLabel}
      aria-disabled={disabled}
      onKeyDown={event => {
        if (event.key === 'Enter' && !disabled) {
          finalOnClick(event);
        }
      }}
      role={role || defaultRole}
      {...(divProps || {})}
    >
      {children}
    </div>
  );
};

interface ClickableProps {
  children: ReactNode,
  ariaLabel: string,
  link?: boolean,
  disabled?: boolean,
  onClick: ClickableHandler,
  style?: CSSProperties,
  divProps?: Partial<HTMLProps<HTMLDivElement>>,
  className?: string,
  role?: string
}

export default Clickable;
