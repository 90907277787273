export const primaryAction = (darkMode?: boolean) => {
  if (darkMode) {
    return '#FFB3B9';
  }
  return '#cb3a46';
};

export const backgroundColor = (darkMode?: boolean) => {
  if (darkMode) {
    return '#2f2e2c';
  }
  return '#f5f3e5';
};

export const textColor = (darkMode?: boolean) => {
  if (darkMode) {
    return '#f5f3e5';
  }
  return '#0B0F3C';
};

export const secondaryAction = (darkMode?: boolean) => {
  if (darkMode) {
    return '#ABABB0';
  }
  return '#81818d';
};
