import { create } from 'zustand';
import {produce} from 'immer';
import { v4 as uuidv4 } from 'uuid';

interface Alert {
  id: string,
  alertText: string
}

interface AlertState {
  alerts: Alert[],
  addAlert: (alertText: string, timeout?: number) => void,
  dismissAlert: (removeId: string) => void
}

export const useAlertsStore = create<AlertState>()((set, get) => ({
  alerts: [],
  addAlert: (alertText, timeout) => set(produce<AlertState>(state => {
    const id = uuidv4();
    state.alerts.push({
      id,
      alertText
    });
    if (timeout) {
      setTimeout(() => {
        get().dismissAlert(id);
      }, timeout);
    }
  })),
  dismissAlert: removeId => set(produce<AlertState>(state => {
    state.alerts = state.alerts.filter(({ id }) => id !== removeId);
  }))
}));
