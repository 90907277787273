import React from 'react';
import {signUp} from 'aws-amplify/auth';
import PropTypes from 'prop-types';
import ImageLogo from '../common/resources/NarrowLogo.svg?react';
import {Authenticator, AuthenticatorProps, useAuthenticator} from '@aws-amplify/ui-react';
// @ts-expect-error: No type def
import ReCAPTCHA from "react-google-recaptcha";
import '@aws-amplify/ui-react/styles.css';
import {useLocation} from 'react-router-dom';
import '../App.css';
import './Login.css';
import {useDarkMode} from "../temporary/localStorageUtils.ts";
import CenterRow from "../components/row/CenterRow.tsx";
import {create} from "zustand";

export const TOWNGEN_API = 'towngenapi';

function useQuery() {
  const {search} = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface CaptchaState {
  verified: boolean,
  setVerified: (v: boolean) => void,
}

const useCaptcha = create<CaptchaState>()(set => ({
  verified: false,
  setVerified: (verified) => set({verified}),
}));

const components = {
  Header() {
    return (
      <ImageLogo className="login-logo-img"/>
    );
  },
  SignUp: {
    FormFields() {
      const darkMode = useDarkMode();
      const {setVerified} = useCaptcha();
      const {updateForm} = useAuthenticator()


      return (
        <>
          {/* Re-use default `Authenticator.SignUp.FormFields` */}
          <Authenticator.SignUp.FormFields/>
          <CenterRow>
            <ReCAPTCHA sitekey="6LfMsrQpAAAAAAlLLn9W7fqtjAffqrlKVDd04CkU"
                       onChange={() => {
                         setVerified(true);
                         updateForm();
                       }}
                       onExpired={() => setVerified(false)}
                       theme={darkMode ? 'dark' : 'light'}/>
          </CenterRow>
        </>
      );
    },
  }
};

function useInitialState(): 'signIn' | 'signUp' {
  const query = useQuery();
  const state = query.get('authState');
  if (state === 'signIn' || state === 'signUp') {
    return state
  }
  if (state === null) {
    return 'signIn'
  }
  throw new Error("Unknown auth state");
}

function Authenticated({children}: AuthenticatorProps) {
  const initialState = useInitialState()

  return (
    <Authenticator loginMechanisms={['username', 'email']} className="authenticator" initialState={initialState}
                   components={components} services={{
      async handleSignUp(formData) {
        if (!useCaptcha.getState().verified) {
          return Promise.reject("Must not be a robot - verify via captcha");
        }
        const {username, password, options} = formData;
        return signUp({
          username,
          password,
          options,
        });
      },
    }}>
      {children}
    </Authenticator>
  );
}

Authenticated.propTypes = {
  children: PropTypes.func.isRequired
};

export default Authenticated;
