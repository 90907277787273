import * as React from "react";
const SvgNarrowLogo = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: 400, height: 100, viewBox: "0 0 400 100", xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("desc", null, "Created with Fabric.js 4.6.0"), /* @__PURE__ */ React.createElement("defs", null), /* @__PURE__ */ React.createElement("g", { transform: "matrix(1 0 0 1 200 50)", id: "yMYMjpB6uZLv3e15NTueP" }, /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "rgb(48,32,239)",
  strokeWidth: 0,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeDashoffset: 0,
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  fill: "rgb(245,243,229)",
  fillRule: "nonzero",
  opacity: 1
}, vectorEffect: "non-scaling-stroke", transform: " translate(-200, -50)", d: "M 0 0 L 400 0 L 400 100 L 0 100 z", strokeLinecap: "round" })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(1 0 0 1 254.1 49.75)", style: {}, id: "SWaeQp1jG1DhTfus8Rx-d" }, /* @__PURE__ */ React.createElement("text", { xmlSpace: "preserve", fontFamily: "Cinzel", fontSize: 33, fontStyle: "normal", fontWeight: 600, letterSpacing: 0.066, "line-height": 1, style: {
  stroke: "none",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeDashoffset: 0,
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  fill: "rgb(11,15,60)",
  fillRule: "nonzero",
  opacity: 1,
  whiteSpace: "pre"
} }, /* @__PURE__ */ React.createElement("tspan", { x: -130.5, y: -6.46 }, "F"), /* @__PURE__ */ React.createElement("tspan", { x: -113.97, y: -6.46 }, "a"), /* @__PURE__ */ React.createElement("tspan", { x: -92.45, y: -6.46 }, "n"), /* @__PURE__ */ React.createElement("tspan", { x: -66.68, y: -6.46 }, "t"), /* @__PURE__ */ React.createElement("tspan", { x: -49.18, y: -6.46 }, "a"), /* @__PURE__ */ React.createElement("tspan", { x: -27.4, y: -6.46 }, "s"), /* @__PURE__ */ React.createElement("tspan", { x: -11.01, y: -6.46 }, "y"), /* @__PURE__ */ React.createElement("tspan", { x: 10.07, y: -6.46, style: {
  whiteSpace: "pre"
} }), /* @__PURE__ */ React.createElement("tspan", { x: 18.39, y: -6.46 }, "T"), /* @__PURE__ */ React.createElement("tspan", { x: 38.26, y: -6.46 }, "o"), /* @__PURE__ */ React.createElement("tspan", { x: 63.38, y: -6.46 }, "w"), /* @__PURE__ */ React.createElement("tspan", { x: 92.48, y: -6.46 }, "n"), /* @__PURE__ */ React.createElement("tspan", { x: 118.25, y: -6.46, style: {
  whiteSpace: "pre"
} }), /* @__PURE__ */ React.createElement("tspan", { x: -130.5, y: 30.83 }, "G"), /* @__PURE__ */ React.createElement("tspan", { x: -103.74, y: 30.83 }, "e"), /* @__PURE__ */ React.createElement("tspan", { x: -85.26, y: 30.83 }, "n"), /* @__PURE__ */ React.createElement("tspan", { x: -59.49, y: 30.83 }, "e"), /* @__PURE__ */ React.createElement("tspan", { x: -41.2, y: 30.83 }, "r"), /* @__PURE__ */ React.createElement("tspan", { x: -19.35, y: 30.83 }, "a"), /* @__PURE__ */ React.createElement("tspan", { x: 0.12, y: 30.83 }, "t"), /* @__PURE__ */ React.createElement("tspan", { x: 19.2, y: 30.83 }, "o"), /* @__PURE__ */ React.createElement("tspan", { x: 44.45, y: 30.83 }, "r"))), /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.17 0 0 0.17 59.19 49.88)", id: "wBh8XbcB5XColWEbrNmdy" }, /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "rgb(11,15,60)",
  strokeWidth: 3,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeDashoffset: 0,
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  fill: "rgb(245,243,229)",
  fillRule: "nonzero",
  opacity: 1
}, vectorEffect: "non-scaling-stroke", transform: " translate(0, 0)", d: "M -250 -250 L 250 -250 L 250 250 L -250 250 z", strokeLinecap: "round" })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.51 0 0 0.51 42.99 28.86)", id: "OLWkpFGFx_RB1AXiT0ScX" }, /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "rgb(11,15,60)",
  strokeWidth: 2,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeDashoffset: 0,
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  fill: "rgb(255,255,255)",
  fillOpacity: 0,
  fillRule: "nonzero",
  opacity: 1
}, vectorEffect: "non-scaling-stroke", transform: " translate(0, 0)", d: "M -42.3994 -43.29359 L 42.39941 43.29359", strokeLinecap: "round" })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.24 0 0 0.24 26.44 21)", id: "ez_p3DHfNMV1Npg0AIiJz" }, /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "rgb(11,15,60)",
  strokeWidth: 2,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeDashoffset: 0,
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  fill: "rgb(255,255,255)",
  fillOpacity: 0,
  fillRule: "nonzero",
  opacity: 1
}, vectorEffect: "non-scaling-stroke", transform: " translate(0, 0)", d: "M -42.3994 -43.29359 L 42.39941 43.29359", strokeLinecap: "round" })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.97 0 0 0.97 15.94 41.16)", id: "Vn0VqQlLQVIBYZFc7Xeq-" }, /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "rgb(11,15,60)",
  strokeWidth: 2,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeDashoffset: 0,
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  fill: "rgb(255,255,255)",
  fillOpacity: 0,
  fillRule: "nonzero",
  opacity: 1
}, vectorEffect: "non-scaling-stroke", transform: " translate(0, 0)", d: "M 0 0", strokeLinecap: "round" })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(-0.24 0 0 0.24 26.16 41.49)", id: "8QQ0eOeUa6Do-2eHU_uia" }, /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "rgb(11,15,60)",
  strokeWidth: 2,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeDashoffset: 0,
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  fill: "rgb(255,255,255)",
  fillOpacity: 0,
  fillRule: "nonzero",
  opacity: 1
}, vectorEffect: "non-scaling-stroke", transform: " translate(0, 0)", d: "M -42.3994 -43.29359 L 42.39941 43.29359", strokeLinecap: "round" })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.67 0 0 0.67 68.87 64.21)", id: "YJQX5XDnaDT5k_cmjJEMq" }, /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "rgb(11,15,60)",
  strokeWidth: 2,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeDashoffset: 0,
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  fill: "rgb(255,255,255)",
  fillOpacity: 0,
  fillRule: "nonzero",
  opacity: 1
}, vectorEffect: "non-scaling-stroke", transform: " translate(0, 0)", d: "M -42.3994 -43.29359 L 42.39941 43.29359", strokeLinecap: "round" })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(-0.3 0 0 0.3 28.61 47.48)", id: "3hymJ3mwajh8wPyR_eYWf" }, /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "rgb(11,15,60)",
  strokeWidth: 2,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeDashoffset: 0,
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  fill: "rgb(255,255,255)",
  fillOpacity: 0,
  fillRule: "nonzero",
  opacity: 1
}, vectorEffect: "non-scaling-stroke", transform: " translate(0, 0)", d: "M -42.3994 -43.29359 L 42.39941 43.29359", strokeLinecap: "round" })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(-0.45 0 0 0.45 83.08 31.75)", id: "FWz-zamAayLPEdsYsVScz" }, /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "rgb(11,15,60)",
  strokeWidth: 2,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeDashoffset: 0,
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  fill: "rgb(255,255,255)",
  fillOpacity: 0,
  fillRule: "nonzero",
  opacity: 1
}, vectorEffect: "non-scaling-stroke", transform: " translate(0, 0)", d: "M -42.3994 -43.29359 L 42.39941 43.29359", strokeLinecap: "round" })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(-0.4 0 0 0.4 85.33 37.36)", id: "AasFQCxC4tjkG8IryaHq-" }, /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "rgb(11,15,60)",
  strokeWidth: 2,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeDashoffset: 0,
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  fill: "rgb(255,255,255)",
  fillOpacity: 0,
  fillRule: "nonzero",
  opacity: 1
}, vectorEffect: "non-scaling-stroke", transform: " translate(0, 0)", d: "M -42.3994 -43.29359 L 42.39941 43.29359", strokeLinecap: "round" })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.41 0 0 0.41 85.11 71.85)", id: "nHMpx1v5Son4-cuu3Onqq" }, /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "rgb(11,15,60)",
  strokeWidth: 2,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeDashoffset: 0,
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  fill: "rgb(255,255,255)",
  fillOpacity: 0,
  fillRule: "nonzero",
  opacity: 1
}, vectorEffect: "non-scaling-stroke", transform: " translate(0, 0)", d: "M -42.3994 -43.29359 L 42.39941 43.29359", strokeLinecap: "round" })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(4.37 4.37 -2.5 2.5 46.66 64.26)", id: "VxTKyseh6U3OCGuJl7MX1" }, /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "rgb(11,15,60)",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeDashoffset: 0,
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  fill: "rgb(128,104,64)",
  fillRule: "nonzero",
  opacity: 1
}, vectorEffect: "non-scaling-stroke", transform: " translate(0, 0)", d: "M -3.38295 -3.38295 L 3.38295 -3.38295 L 3.38295 3.38295 L -3.38295 3.38295 z", strokeLinecap: "round" })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(1.61 1.61 -3.64 3.64 71.39 27.69)", id: "v4mgcgSXwjFZRKGNiQg5X" }, /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "rgb(11,15,60)",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeDashoffset: 0,
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  fill: "rgb(128,104,64)",
  fillRule: "nonzero",
  opacity: 1
}, vectorEffect: "non-scaling-stroke", transform: " translate(0, 0)", d: "M -3.38295 -3.38295 L 3.38295 -3.38295 L 3.38295 3.38295 L -3.38295 3.38295 z", strokeLinecap: "round" })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(2.88 2.88 -1.28 1.28 86.87 59.82)", id: "JNGOkEScF_7kmp8L4btr_" }, /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "rgb(11,15,60)",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeDashoffset: 0,
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  fill: "rgb(128,104,64)",
  fillRule: "nonzero",
  opacity: 1
}, vectorEffect: "non-scaling-stroke", transform: " translate(0, 0)", d: "M -3.38295 -3.38295 L 3.38295 -3.38295 L 3.38295 3.38295 L -3.38295 3.38295 z", strokeLinecap: "round" })));
export default SvgNarrowLogo;
