import '../Common.css';
import {CSSProperties, ReactNode} from 'react';

interface InteractiveElementProps {
  children: ReactNode,
  className?: string,
  style?: CSSProperties,
  circle?: boolean,
  disabled?: boolean,
  noBorder?: boolean,
}

const InteractiveElement = ({ children, style, circle, noBorder, className, disabled }: InteractiveElementProps) => (
  <div
    className={`${circle ? 'interactive-element-circular' : ''}
      ${className || ''}
      ${noBorder || disabled ? '' : 'interactive-element-border'}
      ${!noBorder && disabled ? 'interactive-element-border-disabled' : ''}
      ${disabled ? 'interactive-element-disabled' : 'interactive-element'}`}
    style={style}
  >
    {children}
  </div>
);

export default InteractiveElement;
