import {lazy, ReactNode, Suspense} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Authenticator} from '@aws-amplify/ui-react';

import {ThemeProvider} from '@mui/material';
import * as Sentry from '@sentry/react';
import {createTheme} from '@mui/material/styles';

import {Amplify} from 'aws-amplify';
import {primaryAction, secondaryAction} from './components/Colours';
import {Alerts} from './components/alerts/Alerts';
import Loader from './components/Loader';
import {useCssTheme, useDarkMode} from './temporary/localStorageUtils';
import getBaseUrl from './common/BaseUrl';
import {TOWNGEN_API} from './home/Authenticated';
import {createHeader} from "./home/authUtils";
import {getCurrentStage} from "./common/config.ts";
import {Fallback} from "./components/fallback/Fallback.tsx";

const demoConfig = {
  // REQUIRED - Amazon Cognito Region
  region: 'eu-west-1',

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: 'eu-west-1_X5urGTi8u',

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolClientId: '72jote3g3snujvbeg27mrjdqbf'
};

const prodConfig = {
  // REQUIRED - Amazon Cognito Region
  region: 'eu-west-1',

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: 'eu-west-1_GRIi1BmV1',

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolClientId: '4sjngg6g5r8dnaa6rm03von3du'
};

Amplify.configure(
  {
    Auth: {
      Cognito: getCurrentStage() === 'prod' ? prodConfig : demoConfig
    },
    API: {
      REST: {
        [TOWNGEN_API]: {
          endpoint: getBaseUrl()
        }
      }
    }
  },
  {
    API: {
      REST: {
        headers: createHeader
      }
    }
  }
);

const ThreeJsPages = lazy(() => import('./ThreeJsPages'));
const Landing = lazy(() => import('./landing/Landing'));
const OpenSourceAcknowledgements = lazy(() => import('./landing/other/OpenSourceAcknowledgements'));
const CreativeLicense = lazy(() => import('./landing/other/CreativeLicense'));

function App() {
  return (
    <CssThemeProvider>
        <Authenticator.Provider>
            <AppRoutes />
        </Authenticator.Provider>
    </CssThemeProvider>
  );
}
function AppRoutes() {
  const darkMode = useDarkMode();
  const theme = createTheme({
    palette: {
      primary: {
        main: primaryAction(darkMode)
      },
      secondary: {
        main: secondaryAction(darkMode)
      },
      info: {
        main: primaryAction(darkMode),
      },
      mode: darkMode ? 'dark' : undefined
    },
    components: {
      // Name of the component
      MuiTableCell: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            backgroundColor: 'var(--background)'
          }
        }
      }
    },
    typography: {
      fontFamily: ['"Noto Sans JP"', 'sans-serif'].join(',')
    }
  });
  return (
    <ThemeProvider theme={theme}>
      <div className="main-content">
        <Sentry.ErrorBoundary fallback={error => <Fallback error={error}/>}>
          <Suspense fallback={<Loader isRequesting loaderClassName="center-item" />}>
            <Router>
              <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/*" element={<ThreeJsPages />} />
                <Route path="/open-source-acknowledgements" element={<OpenSourceAcknowledgements />} />
                <Route path="/creative-license" element={<CreativeLicense />} />
              </Routes>
            </Router>
          </Suspense>
          <Alerts />
        </Sentry.ErrorBoundary>
      </div>
    </ThemeProvider>
  );
}

interface CssThemeProviderProps {
    children: ReactNode
}
function CssThemeProvider({ children }: CssThemeProviderProps) {
  const { theme } = useCssTheme();
  return (
    <div className={`root-component default-color-theme default-font-theme ${theme}`}>
      {children}
    </div>
  );
}

export default App;
