import { create } from 'zustand';
import {getGPUTier, TierResult} from 'detect-gpu';
import {WEBGL_SUPPORTED} from "./utils/webGlSupportedStore.ts";


export type AntialiasOption = 'DEFAULT_HARDWARE'|'SSAO';

const ANTIALIAS_COOKIE = 'ANTIALIAS_COOKIE';
const SHADOWS_COOKIE = 'SHADOWS_COOKIE';
const BUILDING_RENDER_OPTION_COOKIE = 'BUILDING_SHAPES_COOKIE';
const TREE_PERCENTAGE_COOKIE = 'TREE_PERCENTAGE_COOKIE';
const THREE_DIMENSIONS_COOKIE = 'THREE_DIMENSIONS_COOKIE';
const SVG_RENDERER_COOKIE = 'SVG_RENDERER_COOKIE';

export type BuildingRoofRenderOption = 'FLAT' | 'FLAT_TEXTURE' | 'FLAT_3D' | '3D_ROOF'

interface GpuTierState {
  data: TierResult|null,
  isRequesting: boolean,
  setIsRequesting: () => void,
  setData: (data: TierResult) => void
}

const useGpuTierStore = create<GpuTierState>()(set => ({
  data: null,
  isRequesting: false,
  setIsRequesting: () => set({isRequesting: true}),
  setData: data => set({data, isRequesting: false})
}));

interface RenderOptionsState {
  antialiasing: AntialiasOption|null,
  shadows: string|null,
  buildingRoofRenderOption: BuildingRoofRenderOption|null,
  treePercentage: string|null,
  threeDimensions: string|null,
  svgRenderer: string|null,
  forceRefresh: boolean,
  setOptions: (antialiasing: AntialiasOption,
               shadows: boolean,
               buildingRoofRenderOption: BuildingRoofRenderOption,
               treePercentage: number,
               threeDimensions: boolean,
               svgRenderer: boolean) => void,
  enableSvgRenderer: () => void,
  confirmRefresh: () => void
}

export const useRenderOptionsStore = create<RenderOptionsState>()((set) => ({
  antialiasing: window.localStorage.getItem(ANTIALIAS_COOKIE) as AntialiasOption|null,
  shadows: window.localStorage.getItem(SHADOWS_COOKIE),
  buildingRoofRenderOption: window.localStorage.getItem(BUILDING_RENDER_OPTION_COOKIE) as BuildingRoofRenderOption|null,
  treePercentage: window.localStorage.getItem(TREE_PERCENTAGE_COOKIE),
  threeDimensions: window.localStorage.getItem(THREE_DIMENSIONS_COOKIE),
  svgRenderer: window.localStorage.getItem(SVG_RENDERER_COOKIE),
  forceRefresh: false,
  setOptions: (antialiasing, shadows, buildingRoofRenderOption, treePercentage, threeDimensions, svgRenderer) => {
    window.localStorage.setItem(ANTIALIAS_COOKIE, antialiasing);
    window.localStorage.setItem(SHADOWS_COOKIE, String(shadows));
    window.localStorage.setItem(BUILDING_RENDER_OPTION_COOKIE, buildingRoofRenderOption);
    window.localStorage.setItem(TREE_PERCENTAGE_COOKIE, String(treePercentage));
    window.localStorage.setItem(THREE_DIMENSIONS_COOKIE, String(threeDimensions));
    window.localStorage.setItem(SVG_RENDERER_COOKIE, String(svgRenderer));
    set({antialiasing, shadows: `${shadows}`, buildingRoofRenderOption, treePercentage: String(treePercentage), threeDimensions: String(threeDimensions), forceRefresh: true, svgRenderer: String(svgRenderer)});
  },
  enableSvgRenderer: () => set({ svgRenderer: "true" }),
  confirmRefresh: () => set({forceRefresh: false})
}));

export interface RenderState {
  antialiasing: AntialiasOption,
  shadows: boolean,
  buildingRoofRenderOption: BuildingRoofRenderOption,
  treePercentage: number,
  threeDimensional: boolean,
  svgRenderer: boolean,
  ready: boolean
}

export function useRenderOptions(): RenderState;
export function useRenderOptions<T>(selector: (state: RenderState) => T): T;
export function useRenderOptions<T>(selector?: (state: RenderState) => T): T|RenderState {
  const {antialiasing, shadows, buildingRoofRenderOption, treePercentage, threeDimensions, svgRenderer} = useRenderOptionsStore();
  const {data, isRequesting, setIsRequesting, setData} = useGpuTierStore();

  if ([antialiasing, shadows, buildingRoofRenderOption, treePercentage, svgRenderer].includes(null) && !data && !isRequesting) {
    setIsRequesting();
    getGPUTier().then(result => {
      setData(result);
    });
  }

  const svgRendererVal = !WEBGL_SUPPORTED || (svgRenderer === null ? false : svgRenderer === 'true')

  const state: RenderState = {
    antialiasing: antialiasing || appleOrIntelPerformance(data, 'DEFAULT_HARDWARE', 'SSAO'),
    shadows: shadows === null ? mobileOrLowPerformance(data, false, true) : shadows === 'true',
    buildingRoofRenderOption: buildingRoofRenderOption !== null ? buildingRoofRenderOption : mobileOrLowPerformance<BuildingRoofRenderOption>(data, 'FLAT_TEXTURE', 'FLAT_3D'),
    treePercentage: treePercentage ? Number(treePercentage) : mobileOrLowPerformance(data, 50, 100),
    threeDimensional: !svgRendererVal && (threeDimensions === null ? false : threeDimensions === 'true'),
    svgRenderer: svgRendererVal,
    ready: ![antialiasing, shadows, buildingRoofRenderOption, treePercentage].includes(null) || !!data
  };
  if (selector) {
    return selector(state);
  }
  return state as T;
}

function mobileOrLowPerformance<T>(data: TierResult|null, mobile: T, notMobile: T) {
  if (!data || data.isMobile || data.tier === 1) {
    return mobile;
  }
  return notMobile;
}

function appleOrIntelPerformance<T>(data: TierResult|null, low: T, high: T) {
  if (!data || data.gpu?.startsWith('intel') || data.gpu?.startsWith('apple')) {
    return low;
  }
  return high;
}
