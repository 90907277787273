import { MdClose } from 'react-icons/md';
import { useAlertsStore } from './alertStore';
import IconLabelRow from '../interaction/IconLabelRow';
import './Alerts.css';

interface Alert {
  id: string,
  alertText: string
}

export const Alerts = () => {
  const { alerts, dismissAlert }: {alerts: Alert[], dismissAlert: (alertId: string) => void} = useAlertsStore();
  return (
    <div className="alerts-overlay">
      {alerts.map(({ id, alertText }) => (
        <div key={id} className="alert-card">
          { alertText }
          <IconLabelRow onClick={() => dismissAlert(id)}>
            <MdClose size={20} />
          </IconLabelRow>
        </div>
      ))}
    </div>
  );
};
