export function getCurrentStage(): 'test'|'prod' {
  const nimbusStage: string = String("${NIMBUS_DEPLOYMENT_STAGE}")
  if (nimbusStage == 'test') {
    return 'test';
  }
  if (nimbusStage == 'prod') {
    return 'prod';
  }
  return 'test';
}
