import {fetchAuthSession} from "aws-amplify/auth";
import { getTemporarySettlementId } from '../temporary/localStorageUtils';

export const createHeader = async () => {
    const currentUser = await fetchAuthSession().catch(() => null);
    let authToken;
    if (currentUser?.tokens?.accessToken) {
        authToken = `Bearer ${currentUser.tokens.accessToken}`;
    } else if (getTemporarySettlementId()) {
        authToken = `Unauthenticated ${getTemporarySettlementId()}`;
    } else {
        authToken = 'Unauthenticated';
    }
    return { Authorization: authToken };
};
