import ReactDOM from 'react-dom/client'
import React, {StrictMode} from 'react'
import App from './App'
import './index.css'
import * as Sentry from '@sentry/react';
import './index.css';
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from "react-router-dom";

Sentry.init({
  dsn: 'https://c7fe8dbd72f44a8885784b13f69a0430@o4504838313410560.ingest.sentry.io/4504838405423104',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 0.001
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App/>
  </StrictMode>,
)
