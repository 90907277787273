import { v4 as uuid } from 'uuid';
import { create } from 'zustand';
import { useAlertsStore } from '../components/alerts/alertStore';

const TEMP_SETTLEMENT_ID = 'TEMP_SETTLEMENT_ID';
const TEMP_SETTLEMENT_LAST_CREATED = 'TEMP_SETTLEMENT_LAST_CREATED';
const SAVED_SETTLEMENT_SETTINGS = 'SAVED_SETTLEMENT_SETTINGS';
const CSS_THEME = 'CSS_THEME';

const ONE_DAY_MILLIS = 1000 * 60 * 60 * 24;

export const getTemporarySettlementId = () => window.localStorage.getItem(TEMP_SETTLEMENT_ID);

export const temporarySettlementExists = () => {
  const lastCreated = window.localStorage.getItem(TEMP_SETTLEMENT_LAST_CREATED);
  return lastCreated && (Date.now() - Number(lastCreated) < ONE_DAY_MILLIS);
};

export const clearTemporarySettlementCookies = () => {
  window.localStorage.removeItem(TEMP_SETTLEMENT_ID);
  window.localStorage.removeItem(TEMP_SETTLEMENT_LAST_CREATED);
};

export const getCssTheme = () => window.localStorage.getItem(CSS_THEME) || '';
export const setCssTheme = (cssTheme: string) => {
  window.localStorage.setItem(CSS_THEME, cssTheme);
  useCssTheme.getState().setTheme(cssTheme);
};

interface CssThemeState {
  theme: string,
  setTheme: (theme: string) => void
}

export const useCssTheme = create<CssThemeState>()((set) => ({
  theme: getCssTheme(),
  setTheme: theme => set({ theme })
}));

export const useDarkMode = () => {
  const { theme } = useCssTheme();
  return theme === 'dark-mode-theme';
};

export const inDarkMode = () => getCssTheme() === 'dark-mode-theme';

/** Ensure you have consent for cookies */
export const createNewTemporarySettlement = () => {
  const existing = getTemporarySettlementId();
  if (!existing) {
    window.localStorage.setItem(TEMP_SETTLEMENT_ID, uuid());
  }
  window.localStorage.setItem(TEMP_SETTLEMENT_LAST_CREATED, `${Date.now()}`);
};

export const getSavedSettlementSettings = () => JSON.parse(window.localStorage.getItem(SAVED_SETTLEMENT_SETTINGS) || '{}');

export const settingsWithinSizeLimit = (settings: object) => {
  const existing = window.localStorage.getItem(SAVED_SETTLEMENT_SETTINGS) || '{}';
  const strSettings = JSON.stringify(settings);
  return (existing.length + strSettings.length) < 3000000; // (characters =~ 3mb, ~30 settings
};

export const addSavedSettlementSettings = (name: string, settings: object) => {
  const existing = getSavedSettlementSettings();
  if (!settingsWithinSizeLimit(settings)) {
    useAlertsStore.getState()
      .addAlert('Unable to save form configuration; out of space. Delete other saved configurations to make more space.');
    return;
  }
  existing[name] = settings;
  window.localStorage.setItem(SAVED_SETTLEMENT_SETTINGS, JSON.stringify(existing));
};

export const deleteSavedSettlementSettings = (name: string) => {
  const existing = getSavedSettlementSettings();
  delete existing[name];
  window.localStorage.setItem(SAVED_SETTLEMENT_SETTINGS, JSON.stringify(existing));
};
