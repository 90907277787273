import '../Common.css';
import {CSSProperties, ReactNode, useMemo} from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import Clickable, {ClickableHandler} from './Clickable';
import { useIsHiddenForEditComponent } from '../../interface/overlayStore';

interface IconLabelRowProps {
  text?: string,
  children: ReactNode,
  fontSize?: string|number,
  type?: string,
  ariaLabel?: string,
  onClick?: ClickableHandler,
  reversed?: boolean,
  forSettlementEdit?: boolean,
  style?: CSSProperties,
  dropdownSuffixSize?: number,
  inline?: boolean,
  disabled?: boolean,
}

const IconLabelRow = ({ text, children, fontSize, onClick, reversed, ariaLabel, type, style, inline, dropdownSuffixSize, forSettlementEdit, disabled }: IconLabelRowProps) => {
  const baseHidden = useIsHiddenForEditComponent();
  const isDisabled = (forSettlementEdit && baseHidden) || disabled;

  const hoverClass = useMemo(() => {
    if (isDisabled) {
      return 'icon-label-disabled-icon';
    }
    if (!onClick) {
      return '';
    } if (!type || type === 'background') {
      return 'icon-label-background';
    }
    return 'icon-label-primary';
  }, [onClick, type, isDisabled]);

  const iconColorClass = useMemo(() => {
    if (isDisabled) {
      return 'icon-label-disabled-icon';
    }
    if (type && type === 'primary') {
      return 'icon-label-primary-icon';
    }
    return '';
  }, [type, isDisabled]);

  const reversedClass = reversed ? 'icon-label-icon-reversed ' : 'icon-label-icon ';
  const actualFontSize = fontSize || 15;
  const mainRow = inline ? 'icon-label-row-inline ' : 'icon-label-row ';
  const content = (
    <>
      {text}
      { dropdownSuffixSize && (<MdArrowDropDown size={dropdownSuffixSize} />) }
    </>
  );

  return (
    <MainComponent
      className={mainRow + hoverClass}
      style={{ fontSize: actualFontSize, ...style }}
      onClick={onClick}
      ariaLabel={ariaLabel || text || ''}
      disabled={isDisabled}
    >
      {reversed ? content : null}
      <div className={reversedClass + iconColorClass}>
        {children}
      </div>
      {!reversed ? content : null}
    </MainComponent>
  );
};

const MainComponent = (props: MainComponentProps) => {
  const { onClick, ariaLabel, className, style, children } = props;
  return onClick ? <Clickable {...props} onClick={onClick!}>{children}</Clickable> : <div aria-label={ariaLabel} className={className} style={style}>{children}</div>;
};

interface MainComponentProps {
  onClick?: ClickableHandler,
  ariaLabel: string,
  className?: string,
  style: CSSProperties,
  children: ReactNode,
  disabled?: boolean
}

export default IconLabelRow;
