import {ReactNode} from 'react';
import { ClipLoader } from 'react-spinners';

interface LoaderProps {
  isRequesting: boolean,
  children?: ReactNode,
  loaderClassName?: string,
  text?: string
}

const Loader = ({ isRequesting, text, children, loaderClassName }: LoaderProps) => (
  <>
    {isRequesting && (loaderClassName || text) ? (
      <div className={`${loaderClassName} loading-circle-container`}>
        <p>{text}</p>
        <div className="loading-circle">
          <ClipLoader id="_towngen-loader" />
        </div>
      </div>
    ) : null}
    {isRequesting && !(loaderClassName || text) ? <ClipLoader id="_towngen-loader" /> : null}
    {!isRequesting && children ? children : null}
  </>
);

export default Loader;
