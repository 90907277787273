import '../Common.css';
import {ReactNode} from 'react';
import { Tooltip } from '@mui/material';
import InteractiveElement from './InteractiveElement';

const TooltipPrimaryLinkIcon = ({ children, href, tooltip, circle, length, noBorder, newTab }: TooltipPrimaryLinkIconProps) => (
  <InteractiveElement {...{ circle, noBorder }}>
    <a href={href} target={newTab ? '_blank' : ''} rel="noreferrer" className="no-a-style">
      <span className="visually-hidden">{tooltip}</span>
      <Tooltip title={tooltip} arrow enterDelay={300}>
        <div style={{ width: `${length || 15}px`, height: `${length || 15}px`, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px' }}>
          { children }
        </div>
      </Tooltip>
    </a>
  </InteractiveElement>
);

interface TooltipPrimaryLinkIconProps {
  children: ReactNode,
  tooltip: string,
  circle?: boolean,
  noBorder?: boolean,
  length?: number,
  href: string,
  newTab?: boolean
}

export default TooltipPrimaryLinkIcon;
