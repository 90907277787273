import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export const Context = {
  DEFAULT: 'DEFAULT',
  FOUNDRY_VTT: 'FOUNDRY_VTT'
};

export const useContext = () => {
  const { search } = useLocation();

  return useMemo(() => {
    const context = new URLSearchParams(search).get('context');
    if (context === 'foundryvtt') {
      return Context.FOUNDRY_VTT;
    }
    return Context.DEFAULT;
  }, [search]);
};
